<template>
  <!--swiper-->
  <div class="content-swiper swiper-container" ref="content_swiper" :key="swiperKey">
    <div class="swiper-wrapper" ref="swiper_wrapper">
      <div
        class="swiper-slide"
        v-for="(item) in durations"
        :key="item.sale_time"
        v-show="item.active == true"
      >
        <template v-if="item.data.length">
          <div class="goods-list">
            <!-- has goods -->
            <good-card
            v-for="(e, idx) in item.data.slice(0,10)"
            :key="idx"
            :couponAmount="e.couponAmount"
            :isSoldOut="e.isSoldOut"
            :title="e.title"
            :pic="e.pic"
            :isvalid="e.isvalid"
            :itemUrl="e.itemUrl"
            :isYuyue="e.isYuyue"
            :miaoshaId="e.miaoshaId"
            :maxCountView="e.maxCountView"
            :miaoshaType="e.miaoshaType"
            :numIid="String(e.numIid)"
            :soldPer="e.soldPer"
            :taolijinAmount="e.taolijinAmount"
            :tkPriceUser="e.tkPriceUser"
            :zkFinalPrice="e.zkFinalPrice"
            :zkFinalPriceMiaosha="e.zkFinalPriceMiaosha"
            :activeDuration="ownActiveDuration"
          ></good-card>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "goods-tab-content",
  components:{
    'good-card': () => import('./GoodCard.vue')
  },
  props: {
    durations: Array,
    currentDuration: {},
    activeDuration: {}
  },
  data() {
    return {
      swiperKey: 0,
      ownActiveDuration: {}
    };
  },
  watch:{
    activeDuration:{
      deep: true,
      handler(newVal) {
        this.ownActiveDuration = newVal
        // console.log("data activeDuration:", this.ownActiveDuration)
      }
    }
  },
  created() {
    this.ownActiveDuration = this.activeDuration
    // console.log("prop activeDuration:", this.activeDuration)
  },
  computed: {},
  methods: {

    // 切换内容层
    slideTo(idx) {
      var slideWidth = 846;
      var scrollLeft = -slideWidth * idx;
      this.$refs.swiper_wrapper.style.transform =
        "translate3d(" + scrollLeft + "px" + ",0,0)";
    },


    refresh() {
      this.swiperKey += 1
    }
  },
};
</script>
<style lang="css" scoped>
.swiper-slide {
  min-height: 200px;
  background: #fff;
}
.goods-list {
  padding: 20px;
}
</style>
